<template>
  <div :class="role ? 'container_is_auth' : 'container_no_auth'">
    <Nav v-if="!role" />
    <div :class="role ? 'child_is_auth' : 'child_no_auth'" v-if="!loading">
      <div v-if="article">
        <v-card
          class="radius-card width__thread"
          v-for="item in article.data"
          :key="item.id"
        >
          <v-img
            v-if="item.image"
            :src="`${env}/artikel/upload/${item.user_id}/${item.image}`"
            class="img-fit"
          ></v-img>

          <section class="px-5 py-3 d-flex justify-space-between">
            <div>
              <v-chip
                class="white--text mr-3"
                :color="
                  item.nama_kategori == 'Psikologi Klinis'
                    ? 'a__'
                    : item.nama_kategori == 'Psikologi Pendidikan'
                    ? 'b__'
                    : 'c__'
                "
                >{{ item.nama_kategori }}</v-chip
              >

              <v-icon v-if="item.visible == 'public'">
                mdi-earth
              </v-icon>
              <v-icon v-if="item.visible == 'private'">
                mdi-account-multiple
              </v-icon>
              <h1>{{ item.judul }}</h1>
            </div>
            <div class="d-flex align-center">
              <v-btn
                v-if="item.status_blokir == 0"
                class="mr-2"
                color="blue"
                dark
                @click="share(item)"
                ><v-icon class="mr-1">mdi-share-variant</v-icon> share</v-btn
              >
              <v-btn
                v-if="item.status_blokir == 1"
                color="green"
                dark
                @click="unblock(item, 0)"
                ><v-icon class="mr-1">mdi-checkbox-marked-circle</v-icon>
                unblock</v-btn
              >
              <v-btn
                v-if="role == 1 && item.status_blokir == 0"
                color="red"
                dark
                @click="block(item, 1)"
                ><v-icon class="mr-1">mdi-cancel</v-icon> block</v-btn
              >
            </div>
          </section>
          <v-divider></v-divider>
          <article class="pa-5">
            <div v-html="item.isi"></div>
          </article>
          <v-divider></v-divider>
          <v-card-actions>
            <section
              v-for="(user, idx) in item.relationships"
              :key="`user-${idx}`"
            >
              <div class="d-flex align-center pa-3" v-if="user.user">
                <v-avatar class="mr-3">
                  <img
                    :src="
                      user.user.data.photo_profile
                        ? `${env}/upload/photo_profile/${item.user_id}/${user.user.data.photo_profile}`
                        : `${env}/images/logo.png`
                    "
                    class="img-fit"
                    alt=""
                  />
                </v-avatar>
                <div>
                  <h4>{{ user.user.data.nama_lengkap }}</h4>
                </div>
              </div>
            </section>
            <v-spacer></v-spacer>
            <div>
              <p class="ma-0">
                <v-icon class="mr-3">mdi-eye</v-icon>{{ item.views }}
                <v-btn
                  text
                  color="blue"
                  @click="like(item.id)"
                  content="like"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100,
                  }"
                  v-if="!item.relationships[1].like.isLike"
                >
                  <v-icon>mdi-heart</v-icon>
                  <span>{{ item.relationships[1].like.data }}</span>
                </v-btn>
                <v-btn
                  text
                  color="red"
                  @click="unlike(item.id)"
                  content="like"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100,
                  }"
                  v-if="item.relationships[1].like.isLike"
                >
                  <v-icon>mdi-heart</v-icon>
                  <span>{{ item.relationships[1].like.data }}</span>
                </v-btn>
              </p>
              <p class="ma-0 grey--text">
                {{ $date(item.created_at).format("dddd, MMMM, YYYY") }}
              </p>
            </div>
          </v-card-actions>
        </v-card>
      </div>
      <div v-if="role">
        <v-card class="radius-card pa-3 mt-3">
          <div
            class="d-flex algn-center justify-center flex-column"
            v-if="!comment"
          >
            <img src="../../assets/img/404.svg" height="100px" alt="404" />
            <h4 class="text-center">No Comment</h4>
          </div>
          <div class="pa-3" v-else>
            <v-card
              class="radius-card pa-3 mb-2"
              v-for="(comments, idx) in comment.data"
              :key="idx"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex">
                  <v-avatar class="mr-3"
                    ><img
                      :src="
                        comments.relationships[0].user.data.photo_profile
                          ? `${env}/upload/photo_profile/${comments.user_id}/${comments.relationships[0].user.data.photo_profile}`
                          : `${env}/images/logo.png`
                      "
                      class="img-fit"
                      alt=""
                  /></v-avatar>
                  <div class="d-flex align-center">
                    <h4>{{ comments.relationships[0].user.data.username }}</h4>
                    <span class="mx-1"><b>.</b></span>
                    <small class="grey--text">{{
                      $date(comments.created_at).fromNow()
                    }}</small>
                  </div>
                </div>
                <div v-if="comments.user_id == id">
                  <v-btn icon small color="blue" @click="editComment(comments)"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  >
                  <v-btn icon color="blue" @click="delComment(comments.id)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </div>
              </div>
              <div class="mt-2">
                <p>{{ comments.content }}</p>
              </div>
            </v-card>
          </div>
          <div class="d-flex flex-column pa-3">
            <v-textarea
              name="comment"
              id="fieldComment"
              hide-details
              v-model="content"
              label="Write something!"
              auto-grow
              solo
            ></v-textarea>
            <div>
              <v-spacer></v-spacer>
              <v-btn class="mt-3 blue" dark @click="commented(article)"
                ><v-icon class="mr-2">mdi-tooltip-check</v-icon>Comment</v-btn
              >
            </div>
            <div id="comment"></div>
          </div>
        </v-card>
      </div>
      <div class="pa-3">
        <EditComment
          v-bind:dialogComment="dialogComment"
          v-bind:dataComment="dataComment"
          @close="closeDialog(1)"
          @fetch="fetchComment"
        />
        <ShareButton
          v-bind:dialogShare="dialogShare"
          v-bind:dataArticle="dataArticle"
          @close="closeDialog(2)"
        />
      </div>
    </div>
    <div :class="role ? 'child_is_auth' : 'child_no_auth'" v-if="loading">
      <v-skeleton-loader
        type="card-avatar, article, actions"
      ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
import Nav from "../../components/General/Nav.vue";
import EditComment from "../../components/Psycholog/Modal/editCommentLibrary.vue";
import ShareButton from "../../components/General/Modal/shareButton.vue";

export default {
  components: { Nav, EditComment, ShareButton },
  name: "viewArtikel",
  computed: {
    ...mapState({
      article: (state) => state.library.articleDetail,
      comment: (state) => state.library.articleComment,
      env: (state) => state.API_URL,
      role: (state) => state.role,
      id: (state) => state.id,
    }),
  },
  data() {
    return {
      loading: false,
      content: "",
      dataComment: null,
      dialogComment: false,
      dialogShare: false,
      dataArticle: null,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    share(data) {
      this.dataArticle = {
        url: `https://pam.gamifindo.com/public/article/${data.id}/view`,
        title: data.judul,
      };
      this.dialogShare = true;
    },
    fetch(reload) {
      if (!reload) {
        this.loading = true;
      }
      let id = this.$route.params.id;
      if (this.$route.params.visible) {
        this.$store.dispatch("library/articleByIdPublic", id).then(() => {
          this.loading = false;
          if (this.role) {
            this.fetchComment();
          }
        });
      } else {
        this.$store.dispatch("library/articleById", id).then(() => {
          this.loading = false;
        });
      }
    },
    fetchComment() {
      let id = this.$route.params.id;
      this.$store.dispatch("library/listCommentArticle", id);
    },
    commented(item) {
      let data = {
        commentable_id: item.data[0].id,
        content: this.content,
      };
      this.$store.dispatch("library/createCommentArticle", data).then(() => {
        this.content = "";
        this.fetchComment();
      });
    },
    editComment(data) {
      this.dataComment = data;
      this.dialogComment = true;
    },
    closeDialog() {
      this.dataComment = null;
      this.dialogComment = false;
      this.dialogShare = false;
      this.dataArticle = null;
    },
    delComment(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("library/deleteCommentArticle", id).then(() => {
            this.fetchComment();
            Swal.fire({
              icon: "success",
              title: "Komentar terhapus!",
              showConfirmButton: false,
              position: "top-end",
              toast: true,
              timer: 3000,
            });
          });
        }
      });
    },

    like(id) {
      if (this.role) {
        this.$store.dispatch("library/likeArticle", id).then(() => {
          this.fetch(true);
        });
      } else {
        console.log("suki dayo");
      }
    },

    unlike(id) {
      this.$store.dispatch("library/unlikeArticle", id).then(() => {
        this.fetch(true);
      });
    },

    unblock(item, status_blokir, alasan_blokir = "") {
      let data = {
        item: item,
        status_blokir: status_blokir,
        alasan_blokir: alasan_blokir,
      };
      Swal.fire({
        title: "Apakah anda yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("library/statusBlockArticle", data).then(() => {
            this.fetch(true);
            Swal.fire({
              icon: "success",
              title: "Artikel diunblock!",
              showConfirmButton: false,
              position: "top-end",
              toast: true,
              timer: 3000,
            });
          });
        }
      });
    },

    block(item, status_blokir) {
      Swal.fire({
        title: "Apakah anda yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true,
      }).then(() => {
        Swal.fire({
          title: "Masukan alasan pemblokiran",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Ok",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            let data = {
              item: item,
              status_blokir: status_blokir,
              alasan_blokir: result.value,
            };
            console.log("result value", result.value);
            console.log("result value 1", data);
            this.$store
              .dispatch("library/statusBlockArticle", data)
              .then((response) => {
                if (response.status != "success") {
                  throw new Error(response);
                }
                this.fetch(true);
                Swal.fire({
                  title: `Artikel telah diblokir karena ${result.value}`,
                });
              })
              .catch((error) => {
                Swal.showValidationMessage(`Permintaan gagal: ${error}`);
              });
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.container_is_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 75%;
  margin-left: 25%;
}
.container_no_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 100%;
}
.child_is_auth {
  margin-top: 100px;
  padding: 10px;
  width: 100%;
}
.child_no_auth {
  margin-top: 100px !important;
  padding: 10px;
  margin: 0 auto;
  width: 80%;
}
div >>> img {
  /* height: 200px; */
  width: 100%;
}
@media (max-width: 576px) {
  .container_is_auth {
    width: 100%;
    margin-left: 0;
  }
  .child_no_auth {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .container_is_auth {
    width: 100%;
    margin-left: 0;
  }
  .child_no_auth {
    width: 100%;
  }
}
</style>
