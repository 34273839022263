var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.role ? 'container_is_auth' : 'container_no_auth'},[(!_vm.role)?_c('Nav'):_vm._e(),(!_vm.loading)?_c('div',{class:_vm.role ? 'child_is_auth' : 'child_no_auth'},[(_vm.article)?_c('div',_vm._l((_vm.article.data),function(item){return _c('v-card',{key:item.id,staticClass:"radius-card width__thread"},[(item.image)?_c('v-img',{staticClass:"img-fit",attrs:{"src":(_vm.env + "/artikel/upload/" + (item.user_id) + "/" + (item.image))}}):_vm._e(),_c('section',{staticClass:"px-5 py-3 d-flex justify-space-between"},[_c('div',[_c('v-chip',{staticClass:"white--text mr-3",attrs:{"color":item.nama_kategori == 'Psikologi Klinis'
                  ? 'a__'
                  : item.nama_kategori == 'Psikologi Pendidikan'
                  ? 'b__'
                  : 'c__'}},[_vm._v(_vm._s(item.nama_kategori))]),(item.visible == 'public')?_c('v-icon',[_vm._v(" mdi-earth ")]):_vm._e(),(item.visible == 'private')?_c('v-icon',[_vm._v(" mdi-account-multiple ")]):_vm._e(),_c('h1',[_vm._v(_vm._s(item.judul))])],1),_c('div',{staticClass:"d-flex align-center"},[(item.status_blokir == 0)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"blue","dark":""},on:{"click":function($event){return _vm.share(item)}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-share-variant")]),_vm._v(" share")],1):_vm._e(),(item.status_blokir == 1)?_c('v-btn',{attrs:{"color":"green","dark":""},on:{"click":function($event){return _vm.unblock(item, 0)}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-checkbox-marked-circle")]),_vm._v(" unblock")],1):_vm._e(),(_vm.role == 1 && item.status_blokir == 0)?_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.block(item, 1)}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cancel")]),_vm._v(" block")],1):_vm._e()],1)]),_c('v-divider'),_c('article',{staticClass:"pa-5"},[_c('div',{domProps:{"innerHTML":_vm._s(item.isi)}})]),_c('v-divider'),_c('v-card-actions',[_vm._l((item.relationships),function(user,idx){return _c('section',{key:("user-" + idx)},[(user.user)?_c('div',{staticClass:"d-flex align-center pa-3"},[_c('v-avatar',{staticClass:"mr-3"},[_c('img',{staticClass:"img-fit",attrs:{"src":user.user.data.photo_profile
                      ? (_vm.env + "/upload/photo_profile/" + (item.user_id) + "/" + (user.user.data.photo_profile))
                      : (_vm.env + "/images/logo.png"),"alt":""}})]),_c('div',[_c('h4',[_vm._v(_vm._s(user.user.data.nama_lengkap))])])],1):_vm._e()])}),_c('v-spacer'),_c('div',[_c('p',{staticClass:"ma-0"},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-eye")]),_vm._v(_vm._s(item.views)+" "),(!item.relationships[1].like.isLike)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100,
                }),expression:"{\n                  arrow: true,\n                  arrowType: 'round',\n                  animation: 'fade',\n                  theme: 'light',\n                  maxWidth: 100,\n                }"}],attrs:{"text":"","color":"blue","content":"like"},on:{"click":function($event){return _vm.like(item.id)}}},[_c('v-icon',[_vm._v("mdi-heart")]),_c('span',[_vm._v(_vm._s(item.relationships[1].like.data))])],1):_vm._e(),(item.relationships[1].like.isLike)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100,
                }),expression:"{\n                  arrow: true,\n                  arrowType: 'round',\n                  animation: 'fade',\n                  theme: 'light',\n                  maxWidth: 100,\n                }"}],attrs:{"text":"","color":"red","content":"like"},on:{"click":function($event){return _vm.unlike(item.id)}}},[_c('v-icon',[_vm._v("mdi-heart")]),_c('span',[_vm._v(_vm._s(item.relationships[1].like.data))])],1):_vm._e()],1),_c('p',{staticClass:"ma-0 grey--text"},[_vm._v(" "+_vm._s(_vm.$date(item.created_at).format("dddd, MMMM, YYYY"))+" ")])])],2)],1)}),1):_vm._e(),(_vm.role)?_c('div',[_c('v-card',{staticClass:"radius-card pa-3 mt-3"},[(!_vm.comment)?_c('div',{staticClass:"d-flex algn-center justify-center flex-column"},[_c('img',{attrs:{"src":require("../../assets/img/404.svg"),"height":"100px","alt":"404"}}),_c('h4',{staticClass:"text-center"},[_vm._v("No Comment")])]):_c('div',{staticClass:"pa-3"},_vm._l((_vm.comment.data),function(comments,idx){return _c('v-card',{key:idx,staticClass:"radius-card pa-3 mb-2"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"mr-3"},[_c('img',{staticClass:"img-fit",attrs:{"src":comments.relationships[0].user.data.photo_profile
                        ? (_vm.env + "/upload/photo_profile/" + (comments.user_id) + "/" + (comments.relationships[0].user.data.photo_profile))
                        : (_vm.env + "/images/logo.png"),"alt":""}})]),_c('div',{staticClass:"d-flex align-center"},[_c('h4',[_vm._v(_vm._s(comments.relationships[0].user.data.username))]),_c('span',{staticClass:"mx-1"},[_c('b',[_vm._v(".")])]),_c('small',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$date(comments.created_at).fromNow()))])])],1),(comments.user_id == _vm.id)?_c('div',[_c('v-btn',{attrs:{"icon":"","small":"","color":"blue"},on:{"click":function($event){return _vm.editComment(comments)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.delComment(comments.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()]),_c('div',{staticClass:"mt-2"},[_c('p',[_vm._v(_vm._s(comments.content))])])])}),1),_c('div',{staticClass:"d-flex flex-column pa-3"},[_c('v-textarea',{attrs:{"name":"comment","id":"fieldComment","hide-details":"","label":"Write something!","auto-grow":"","solo":""},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('div',[_c('v-spacer'),_c('v-btn',{staticClass:"mt-3 blue",attrs:{"dark":""},on:{"click":function($event){return _vm.commented(_vm.article)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tooltip-check")]),_vm._v("Comment")],1)],1),_c('div',{attrs:{"id":"comment"}})],1)])],1):_vm._e(),_c('div',{staticClass:"pa-3"},[_c('EditComment',{attrs:{"dialogComment":_vm.dialogComment,"dataComment":_vm.dataComment},on:{"close":function($event){return _vm.closeDialog(1)},"fetch":_vm.fetchComment}}),_c('ShareButton',{attrs:{"dialogShare":_vm.dialogShare,"dataArticle":_vm.dataArticle},on:{"close":function($event){return _vm.closeDialog(2)}}})],1)]):_vm._e(),(_vm.loading)?_c('div',{class:_vm.role ? 'child_is_auth' : 'child_no_auth'},[_c('v-skeleton-loader',{attrs:{"type":"card-avatar, article, actions"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }