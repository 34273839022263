<template>
  <v-row justify="start">
    <v-dialog v-model="dialogShare" persistent max-width="400px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Share To</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container v-if="dataArticle">
            <div>
              <ShareNetwork
                network="facebook"
                :url="dataArticle.url"
                :title="dataArticle.title"
                :description="dataArticle.title"
                :quote="dataArticle.title"
                hashtags="PAMPSI"
              >
                <v-btn class="mr-2" color="blue" dark
                  ><v-icon class="mr-2">mdi-facebook</v-icon> Facebook</v-btn
                >
              </ShareNetwork>
              <ShareNetwork
                network="WhatsApp"
                :url="dataArticle.url"
                :title="dataArticle.title"
              >
                <v-btn color="green" dark
                  ><v-icon class="mr-2">mdi-whatsapp</v-icon>Whatsapp</v-btn
                >
              </ShareNetwork>
              <ShareNetwork
                network="LinkedIn"
                :url="dataArticle.url"
                hashtags="PAMPSI"
              >
                <v-btn class="mt-2 mr-2" color="blue" dark
                  ><v-icon class="mr-2">mdi-linkedin</v-icon>LinkedIn</v-btn
                >
              </ShareNetwork>
              <ShareNetwork
                network="Twitter"
                :url="dataArticle.url"
                :title="dataArticle.title"
                hashtags="PAMPSI"
              >
                <v-btn class="mt-2" color="blue" dark
                  ><v-icon class="mr-2">mdi-twitter</v-icon>Twitter</v-btn
                >
              </ShareNetwork>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "shareButton",

  props: ["dialogShare", "dataArticle"],

  data() {
    return {
      nameCampus: ""
    };
  },
  methods: {}
};
</script>

<style></style>
